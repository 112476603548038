import React from "react";
import { Badge, Image, Tooltip } from "@shopify/polaris";
import CustomBadge from "../../../components/customBadge";
import {
  getStatusMessage,
  getStatusDescription,
} from "../../../utils/WarningInfo";
import { Warning, DangerWarning } from "../../../assets";
import { useTranslation } from "react-i18next";

export function OrderStatus({
  orderStatus,
  statusDescription,
  statusMessage,
  processingMethod,
  merchantAccountId,
}) {
  const { t } = useTranslation();

  if (orderStatus === "SUCCESS") {
    return statusDescription ? (
      <Tooltip
        content={
          <div className="d-flex flex-col gap-10">
            <p className="warningDiscription">
              {t(getStatusMessage(statusDescription))}
            </p>
          </div>
        }
      >
        {console.log("successs tooltip", orderStatus)}
        <Badge status="success" as="span" progress="complete">
          <p className="table-data">Success</p>
        </Badge>
      </Tooltip>
    ) : (
      <Badge status="success" as="span" progress="complete">
        {console.log("successs tooltip without", orderStatus)}
        <p className="table-data">Success</p>
      </Badge>
    );
  }

  if (orderStatus === "QUEUED") {
    return (
      <CustomBadge text="Queued" dot="#fff" textColor="#fff" color="#0000FF" />
    );
  }

  if (
    orderStatus === "MISSING_INFO" &&
    (processingMethod === "MISSING_DEFAULT_MERCHANT_ACCOUNT_ID" ||
      processingMethod === "MISSING_MERCHANT_ACCOUNT_ID")
  ) {
    return statusMessage || statusDescription ? (
      <div className="d-flex gap-10 aligns-Items-center ">
        {console.log("MISSING_INFO with tooltip", orderStatus)}
        <CustomBadge
          text="Paypal not connected"
          color="#FFD79D"
          dot="#B98900"
          textColor="black"
        />
        <Tooltip
          content={
            <div className="d-flex flex-col gap-10">
              <p className="warningHeading">
                {t(getStatusMessage(statusMessage))}
              </p>
              <p className="warningDiscription">
                {statusDescription.includes("is not connected to Trackipal")
                  ? t("Merchant_ID_is_not_connected_to_Trackipal", {
                      merchantAccountId,
                    })
                  : t(getStatusDescription(statusDescription))}
              </p>
            </div>
          }
        >
          <Image src={Warning} className="mt-5" width={20} />
        </Tooltip>
      </div>
    ) : (
      <CustomBadge
        text="Paypal not connected"
        color="#FFD79D"
        dot="#B98900"
        textColor="black"
      />
    );
  }

  if (
    (orderStatus === "MISSING_INFO" &&
      processingMethod === "MISSING_TRACKING_NUMBER") ||
    processingMethod === "MISSING_CARRIER" ||
    processingMethod === "MISSING_TRANSACTION_ID"
  ) {
    return statusMessage || statusDescription ? (
      <div className="d-flex gap-10 aligns-Items-center ">
        {console.log("MISSING_INFO with tooltip", orderStatus)}

        <CustomBadge
          text="Unfullfilled"
          color="#FFD79D"
          dot="#B98900"
          textColor="black"
        />
        <Tooltip
          content={
            <div className="d-flex flex-col gap-10">
              <p className="warningHeading">
                {t(getStatusMessage(statusMessage))}
              </p>
              <p className="warningDiscription">
                {t(getStatusDescription(statusDescription))}
              </p>
            </div>
          }
        >
          <Image src={Warning} className="mt-5" width={20} />
        </Tooltip>
      </div>
    ) : (
      <CustomBadge
        text="Unfullfilled"
        color="#FFD79D"
        dot="#B98900"
        textColor="black"
      />
    );
  }

  if (orderStatus === "PROCESSING") {
    return (
      <CustomBadge
        text="Processing"
        color="#2a52be"
        dot="#ffffff"
        textColor="#ffffff"
      />
    );
  }

  if (orderStatus === "FAILED") {
    return statusMessage || statusDescription ? (
      <div className="d-flex gap-10 aligns-Items-center ">
        {console.log("FAILED with tooltip", orderStatus)}

        <Badge status="critical" as="span" progress="complete">
          <p className="table-data">Failed</p>
        </Badge>
        <Tooltip
          content={
            <div className="d-flex flex-col gap-10">
              <p className="warningHeading" style={{ color: "red" }}>
                {t(getStatusMessage(statusMessage))}
              </p>
              <p className="warningDiscription">
                {t(getStatusDescription(statusDescription))}
              </p>
            </div>
          }
        >
          <Image src={DangerWarning} className="mt-5" width={20} />
        </Tooltip>
      </div>
    ) : (
      <Badge status="critical" as="span" progress="complete">
        {console.log("failed without tooltip", orderStatus)}
        <p className="table-data">Failed</p>
      </Badge>
    );
  }

  if (orderStatus === "INVALID_GATEWAY") {
    return statusMessage || statusDescription ? (
      <div className="d-flex gap-10 aligns-Items-center ">
        {console.log("INVALID_GATEWAY with tooltip", orderStatus)}

        <CustomBadge
          text="Invalid Gateway"
          color="#FFD79D"
          dot="#B98900"
          textColor="black"
        />
        <Tooltip
          content={
            <div className="d-flex flex-col gap-10">
              <p className="warningHeading">
                {t(getStatusMessage(statusMessage))}
              </p>
              <p className="warningDiscription">
                {t(getStatusDescription(statusDescription))}
              </p>
            </div>
          }
        >
          <Image src={DangerWarning} className="mt-5" width={20} />
        </Tooltip>
      </div>
    ) : (
      <CustomBadge
        text="Invalid Gateway"
        color="#FFD79D"
        dot="#B98900"
        textColor="black"
      />
    );
  }

  if (orderStatus === "OLD_FULFILLMENT") {
    return <CustomBadge text="Old fulfillment" dot="#B98900" color="#FFD79D" />;
  }

  return null;
}
